import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Helmet } from "react-helmet";

const Sidebar1 = React.lazy(() => import("components/Sidebar1"));
const ApponitmentDoctorList = React.lazy(() => import("pages/OPD/Appointments/ApponitmentsDoctorList"));
const PatientList = React.lazy(() => import("pages/OPD/Appointments/PatientList"));
const BookAppointmentByDoctor = React.lazy(() => import("pages/OPD/Appointments/BookAppointmentByDoctor"));
const ConsultationAll = React.lazy(() => import("pages/OPD/Appointments/Consultaion/ConsultationAll"));
const AddNewMedicines = React.lazy(() => import("pages/OPD/Appointments/Consultaion/Components/AddNewMedicine"));
const AddPatientByDoctor = React.lazy(() => import("pages/OPD/Appointments/AddPatientByDoctor"));
const Billing = React.lazy(() => import("pages/OPD/Appointments/Consultaion/Components/Billing"));
const Sidebar2 = React.lazy(() => import("components/Sidebar2"));
const HospitalSettingDetails = React.lazy(() => import("pages/Hospital Setting/HospitalSettingDetails"));
const RevenueReports = React.lazy(() => import("pages/RevenueReports/RevenueReports"));
const NewPientsReports = React.lazy(() => import("pages/NewPientsReports/NewPientsReports"));
const SpecialzationAdd = React.lazy(() => import("pages/Hospital Setting/Specializations/SpecialzationAdd"));
const EditPatient = React.lazy(() => import("pages/PatientPages/EditPatient"));
const SpecializationUpdate = React.lazy(() => import("pages/Hospital Setting/Specializations/SpecializationUpdate"));
const AddSymptoms = React.lazy(() => import("pages/Hospital Setting/Symptoms/AddSymptoms"));
const OpdHistoryNew = React.lazy(() => import("pages/OPD/MyPatient/OpdHistoryNew"));
const OldPatientReports = React.lazy(() => import("pages/OldPatientrReports/OldPatientReports"));
const TopSymptomsReports = React.lazy(() => import("pages/TopSymptomsReports/TopSymptomsReports"));
const TopDianosisreport = React.lazy(() => import("pages/TopDiagnosisReport/TopDianosisreport"));
const SpecializationImageView = React.lazy(() => import("pages/Hospital Setting/Specializations/SpecializationImageView"));
const UpdateSpecialization = React.lazy(() => import("pages/Hospital Setting/Specializations/UpdateSpecialization"));

const HomeOne = React.lazy(() => import("pages/HomeOne"));
const MultispecialtyHospital = React.lazy(() => import("pages/MultispecialtyHospital/MultispecialtyHospital"));
const PatientHome = React.lazy(() => import("pages/PatientHome/PatientHome"));
const Appointments = React.lazy(() => import("pages/PatientPages/Appointments"));
const AddNewPatient = React.lazy(() => import("pages/PatientPages/AddNewPatient"));
const AddNewPatientProfile = React.lazy(() => import("pages/PatientPages/AddNewPatientProfile"));
const AppointmentUploadReport = React.lazy(() => import("pages/PatientPages/AppointmentUploadReport"));
const AllFamilyMembers = React.lazy(() => import("pages/PatientPages/AllFamilyMembers"));
const BookByDoctor = React.lazy(() => import("pages/PatientBookAppointment/BookByDoctor"));
const BookAppointmentSymptom = React.lazy(() => import("pages/PatientBookAppointment/BookAppointmentSymptom"));
const BookBySpecialization = React.lazy(() => import("pages/PatientBookAppointment/BookBySpecialization"));
const BookBySymptom = React.lazy(() => import("pages/PatientBookAppointment/BookBySymptom"));
const BookAppointmentSlot = React.lazy(() => import("pages/PatientBookAppointment/BookAppointmentSlot"));
const ViewPatientProfile = React.lazy(() => import("pages/PatientPages/ViewPatientProfile"));
const ViewProfile = React.lazy(() => import("pages/PatientPages/ViewProfile"));
const UpdatePasswardPatient = React.lazy(() => import("pages/PatientPages/UpdatePasswardPatient"));
const UpdateMedicine = React.lazy(() => import("pages/Hospital Setting/Medicine/MedicineUpdate"));

const PasswordResetForm = React.lazy(() => import("pages/Forgot Password/PasswordResetForm"));
const OTPForm = React.lazy(() => import("pages/Forgot Password/OTPForm"));
const OTPFormDoctors = React.lazy(() => import("pages/Forgot Password/OTPFormDoctors"));
const PasswordResetFormDoctors = React.lazy(() => import("pages/Forgot Password/PasswordResetFormDoctors"));

const DoctorsOne = React.lazy(() => import("pages/DoctorsOne"));
const MotherChildCare = React.lazy(() => import("pages/Motherandchildcare"));
const AboutUs = React.lazy(() => import("pages/Aboutus"));
const Contact = React.lazy(() => import("pages/Contact"));
const Speciality = React.lazy(() => import("pages/Speciality/Speciality"));
const Privacy = React.lazy(() => import("pages/PrivacyPolicy/Privacy"));
const LogInEmpty = React.lazy(() => import("pages/LogInPage/LogInEmpty"));
const CreateUser = React.lazy(() => import("pages/CreateUser/CreateUser"));
const LoginDoctor = React.lazy(() => import("pages/LogInPage/LoginDoctor"));
const DoctorHome = React.lazy(() => import("pages/DoctorHome/DoctorHome"));
const Appointmentsmanagements = React.lazy(() => import("pages/Appointments Managements/Appointmentsmanagements"));
const UpdatePatientAppointmentsmanagements = React.lazy(() => import("pages/Appointments Managements/UpdatePatient"));
const MyPatient = React.lazy(() => import("pages/OPD/MyPatient/MyPatient"));
const MypatientUpdatePatient = React.lazy(() => import("pages/OPD/MyPatient/UpdatePatient"));
const BookAppointment = React.lazy(() => import("pages/Appointments Managements/BookAppointment"));
const AddPatient = React.lazy(() => import("pages/Appointments Managements/AddPatient"));
const MarkPaid = React.lazy(() => import("pages/Appointments Managements/MarkPaid"));
const BookappointmentAdd = React.lazy(() => import("pages/Appointments Managements/BookappointmentAdd"));
const UploadReport = React.lazy(() => import("pages/Appointments Managements/UploadReport"));
const AddVitals = React.lazy(() => import("pages/Appointments Managements/AddVitals"));
const AddBillView = React.lazy(() => import("pages/Appointments Managements/AddBillView"));
const AddNewSymtoms = React.lazy(() => import("pages/OPD/Appointments/Consultaion/Components/AddNewSymtoms"));
const AddNewInstruction = React.lazy(() => import("pages/OPD/Appointments/Consultaion/Components/AddNewInstruction"));
const UsersList = React.lazy(() => import("pages/UserManagement/UsersList"));
const AddUser = React.lazy(() => import("pages/UserManagement/AddUser"));
const EditUser = React.lazy(() => import("pages/UserManagement/EditUser"));
const UserProfileAll = React.lazy(() => import("pages/UserManagement/UserProfileAll"));
const UserAccess = React.lazy(() => import("pages/UserManagement/UserAccess/UserAccess"));
const UserImageAddUpdate = React.lazy(() => import("pages/UserManagement/UserImageAddUpdate"));
const OPDManagementAll = React.lazy(() => import("pages/OPDManagement/OPDManagementAll"));
const MyProfileImageAddUpdate = React.lazy(() => import("pages/OPDManagement/MyProfileImageAddUpdate"));
const PatientDelete = React.lazy(() => import("pages/PatientDelete/PatientDelete"));
const PatientManagementList = React.lazy(() => import("pages/PatientManagement/PatientList"));
const PatientManagementAddPatient = React.lazy(() => import("pages/PatientManagement/AddPatient"));
const PatientManagementUpdatePatient = React.lazy(() => import("pages/PatientManagement/UpdatePatient"));

const RoomBookingAdmissionDetails = React.lazy(() => import("pages/RoomBooking/AdmissionDetails"));

const RoomBookingAddPatient = React.lazy(() => import("pages/RoomBooking/AddPatient"));
const RoomBookingEditPatient = React.lazy(() => import("pages/RoomBooking/EditPatient"));
const IPDEditPatient = React.lazy(() => import("pages/RoomBooking/IPDEditPatient"));
const RoomBookingPatientManage = React.lazy(() => import("pages/RoomBooking/PatientManage"));
const IpdDetails = React.lazy(() => import("pages/IPDDetails/IpdDetails"));
const RoomBooking = React.lazy(() => import("pages/RoomBooking/RoomBook"));
const CheckBedAvailability = React.lazy(() => import("pages/RoomBooking/CheckBedAvailability"));
const CheckBedAvailabilitytwo = React.lazy(() => import("pages/RoomBooking/CheckBedAvailabilitytwo"));
const ManagePatientRoomBookin = React.lazy(() => import("pages/RoomBooking/ManagePatientRoomBookin"));

const IPDBillingSetup = React.lazy(() => import("pages/IPDBillingSetup/IPDBillingSetup"));
const IPDBedSetup = React.lazy(() => import("pages/IPDBedSetup/IPDBedSetup"));
const ReliginDisplay = React.lazy(() => import("pages/Religin/Religin"));
const IpdWardcreate = React.lazy(() => import("pages/IPDBedSetup/IPDWard/IpdWardcreate"));
const IpdWardUpdate = React.lazy(() => import("pages/IPDBedSetup/IPDWard/IpdWardUpdate"));
const IpdBedcreate = React.lazy(() => import("pages/IPDBedSetup/IPDBed/IpdBedcreate"));
const IpdBedUpdate = React.lazy(() => import("pages/IPDBedSetup/IPDBed/IpdBedUpdate"));
const IpdWardTypecreate = React.lazy(() => import("pages/IPDBedSetup/IPDWardType/IpdWardTypecreate"));
const IpdWrdTypeUpdate = React.lazy(() => import("pages/IPDBedSetup/IPDWardType/IpdWrdTypeUpdate"));
const AddNewBillItem = React.lazy(() => import("pages/IPDBillingSetup/IPDBillItems/AddNewBillItem"));
const UpdateNewBillItem = React.lazy(() => import("pages/IPDBillingSetup/IPDBillItems/EditBillItem"));
const AddNewBillCategory = React.lazy(() => import("pages/IPDBillingSetup/IPDBillCategory/AddNewBillCategory"));
const UpdateNewBillCategory = React.lazy(() => import("pages/IPDBillingSetup/IPDBillCategory/UpdateNewBillCategory"));


const IPDBillAndPay = React.lazy(() => import("pages/IPDBillAndPay/IPDBillAndPay"));
const IPDDischargeMain = React.lazy(() => import("pages/IPDDischarge/IPDDischargeMain"));
const IPDDischargeSetup = React.lazy(() => import("pages/IPDDischargeSetup/IPDDischargeSetup"));
const IPDDichargeCheckListUpdate = React.lazy(() => import("pages/IPDDischargeSetup/IPDDichargeCheckListUpdate"));
const IPDMasterTemplateSetup = React.lazy(() => import("pages/IPDMasterTemplates/IPDMasterTemplateSetup"));
const EditIPDMasterTemplate = React.lazy(() => import("pages/IPDMasterTemplates/EditIPDMasterTemplate"));
const AddIPDMasterTemplate = React.lazy(() => import("pages/IPDMasterTemplates/AddIPDMasterTemplate"));

const IPDDischargeSummaryForAndroid = React.lazy(() => import("pages/IPDDischarge/IPDDischargeSummaryForAndroid"))

const DigiTonicLogin = React.lazy(() => import("pages/DigiTonic/LogInPage"))
const ForgotPassword = React.lazy(() => import("pages/DigiTonic/ForgotPassword"))
const PassWordReset = React.lazy(() => import("pages/DigiTonic/PassWordReset"))

const hospitalName = process.env.REACT_APP_HOSPITAL_NAME || "Default Hospital";
const hospitalLogo = process.env.REACT_APP_HOSPITAL_LOGO || "/Digitonic.ico";

const updateMetaData = () => {
  // Update Title
  document.title = hospitalName;

  // Update Favicon
  // const favicon = document.getElementById('favicon');
  // if (favicon) {
  //   favicon.href = hospitalLogo;
  // }
};

function App() {
  const statusDoctor = sessionStorage.getItem("statusdoctor");
  useEffect(() => {
    updateMetaData();
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center h-screen">
          <div className="relative">
            <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
            <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin"></div>
          </div>
        </div>
      }
    >
      <Helmet>
        <link rel="icon" href={`${hospitalLogo}?v=${new Date().getTime()}`} />
      </Helmet>
      <Router>
        <ProSidebarProvider>
          <Routes>
            <Route
              path="/"
              element={
                hospitalName == "DigiTonic Web App" ? <DigiTonicLogin /> : <HomeOne />
              }
            />
            <Route path="/deleteaccount" element={<PatientDelete />} />
            <Route path="/sidebar" element={statusDoctor ? <Sidebar1 /> : <LoginDoctor />} />
            <Route path="/multispecialtyHospital" element={<MultispecialtyHospital />} />
            <Route path="/motherandchildcare" element={<MotherChildCare />} />
            <Route path="/speciality" element={<Speciality />} />
            <Route path="/doctorsone" element={<DoctorsOne />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/login" element={<LogInEmpty />} />
            <Route path="/createUser" element={<CreateUser />} />
            {/* {Doctor Login} */}
            {/* Nested routes for Doctor Login */}
            <Route path="/loginfordoctor" element={<LoginDoctor />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:id" element={<PassWordReset />} />
            <Route path="/" element={<Sidebar1 />}>
              <Route path="/doctorhome" element={<DoctorHome />} />
              {/* {Book Appointments} */}
              <Route path="/appointmentsmanagements" element={<Appointmentsmanagements />} />
              <Route path="/bookappointment" element={<BookAppointment />} />
              <Route path="/appointment/add/patient" element={<AddPatient />} />
              <Route path="/appointment/booking/:patient_id" element={<BookappointmentAdd />} />
              <Route path="/appointmentmanagement/Update/:patient_id" element={<UpdatePatientAppointmentsmanagements />} />
              <Route path="/appointment/add/uploadReport/:userDetailShiftSlotId" element={<UploadReport />} />
              <Route path="/appointment/add/vitals/:patient_id/:id" element={<AddVitals />} />
              <Route path="/appointment/add/bill_view/:patient_id/:id" element={<AddBillView />} />
              <Route path="/appointment/collect/payment/:patient_id/:userdetail_id/:id" element={<MarkPaid />} />
              {/* {OPD} */}
              {/* {Appointments} */}
              <Route path="/doctorAppointments" element={<ApponitmentDoctorList />} />
              <Route path="/patientlist" element={<PatientList />} />
              <Route path="/patientlist/addpatient" element={<AddPatientByDoctor />} />
              <Route path="/appointment/add/:patient_id/:id" element={<Billing />} />
              <Route path="/appointment/bookingDoctor/:patient_id" element={<BookAppointmentByDoctor />} />
              <Route path="/consultaion/:key/:patient_id/:id" element={<ConsultationAll />} />
              <Route path="/consultaion/add/symptom/:patient_id/:id" element={<AddNewSymtoms />} />
              <Route path="/consultaion/add/medicine/:patient_id/:id" element={<AddNewMedicines />} />
              <Route path="/consultaion/add/instruction/:patient_id/:id" element={<AddNewInstruction />} />
              {/* {MyPatients} */}
              <Route path="/myPatients" element={<MyPatient />} />
              <Route path="/opdHistory/:id" element={<OpdHistoryNew />} />
              <Route path="/History/update/:patient_id" element={<MypatientUpdatePatient />} />
              {/* UsersList */}
              <Route path="/user/management/all" element={<UsersList />} />
              <Route path="/user/management/adduser" element={<AddUser />} />
              <Route path="/user/management/edituser/:id" element={<EditUser />} />
              <Route path="/user/management/profile/:id" element={<UserProfileAll />} />
              <Route path="/user/management/profile/access/:id" element={<UserAccess />} />
              <Route path="/user/management/profile/image/:id" element={<UserImageAddUpdate />} />
              {/* hospital Settings */}
              <Route path="/hospitalsettings" element={<HospitalSettingDetails />} />
              <Route path="/specialization/add" element={<SpecialzationAdd />} />
              <Route path="/specialization/edit/:id" element={<SpecializationUpdate />} />
              <Route path="/specialization/update/:id" element={<UpdateSpecialization />} />
              <Route path="/specialization/view/:id" element={<SpecializationImageView />} />
              <Route path="/symptoms/:id" element={<AddSymptoms />} />
              <Route path="/user/management/edituser/:id" element={<EditUser />} />
              <Route path="/user/management/profile/:id" element={<UserProfileAll />} />
              {/* Reports */}
              <Route path="/revenuereports" element={<RevenueReports />} />
              <Route path="/newpientsreports" element={<NewPientsReports />} />
              <Route path="/oldpientsreports" element={<OldPatientReports />} />
              <Route path="/topsymptomsreports" element={<TopSymptomsReports />} />
              <Route path="/topdiagnosisreports" element={<TopDianosisreport />} />
              {/* OPD Management */}
              <Route path="/settings" element={<OPDManagementAll />} />
              <Route path="/settings/imageupload" element={<MyProfileImageAddUpdate />} />
              {/* Patient Management */}
              <Route path="/patientmanagement/allpatientlist" element={<PatientManagementList />} />
              <Route path="/Patientmanagement/addpatient" element={<PatientManagementAddPatient />} />
              <Route path="/Patientmanagement/update/:patient_id/:patient_signup_master_id" element={<PatientManagementUpdatePatient />} />
              {/* Room Booking */}
              <Route path="/roombooking/admissiondetails" element={<RoomBookingAdmissionDetails />} />
              <Route path="/roombooking/addpatient" element={<RoomBookingAddPatient />} />
              <Route path="/roombooking/editpatient/:SelectedpatientId" element={<RoomBookingEditPatient />} />
              <Route path="/roombooking/PatientManage" element={<RoomBookingPatientManage />} />
              <Route path="/ipdDetails/:id" element={<IpdDetails />} />
              <Route path="/roombooking/:id/:bedId" element={<ManagePatientRoomBookin />} />
              <Route path="/roombooking" element={<RoomBooking />} />
              <Route path="/roombooking/iPDEditPatient/:SelectedpatientId" element={<IPDEditPatient />} />
              <Route path="/roombooking/:SelectpatientId" element={<RoomBooking />} />
              <Route path="/roombooking/CheckBedAvailability/:SelectedpatientId" element={<CheckBedAvailability />} />
              <Route path="/roombooking/CheckBedAvailabilitytwo/:id/:bedId" element={<CheckBedAvailabilitytwo />} />
              {/* {update  Medicine} */}
              <Route path="/updateMedicine" element={<UpdateMedicine />} />

              <Route path="/ipdbillsetup" element={<IPDBillingSetup />} />
              <Route path="/ipdAddNewBillItem" element={<AddNewBillItem />} />
              <Route path="/ipdEditBillItem/:id" element={<UpdateNewBillItem />} />
              <Route path="/addNewBillCaregory" element={<AddNewBillCategory />} />
              <Route path="/editBillCategory/:id" element={<UpdateNewBillCategory />} />

              <Route path="/ipdBillAndPay/:id" element={<IPDBillAndPay />} />
              <Route path="/ipdDichargeSummary/:id" element={<IPDDischargeMain />} />
              <Route path="/ipdDischargeSetup" element={<IPDDischargeSetup />} />
              <Route path="/ipdDischargeSetup/checklist/update/:id" element={<IPDDichargeCheckListUpdate />} />

              {/* hospital IPD Bed Setup */}
              <Route path="/ipdbedsetup" element={<IPDBedSetup />} />
              <Route path="/ipdwardcreate" element={<IpdWardcreate />} />
              <Route path="/ipdwardupdate/:id" element={<IpdWardUpdate />} />
              <Route path="/ipdbedcreate" element={<IpdBedcreate />} />
              <Route path="/ipdbedupdate" element={<IpdBedUpdate />} />
              <Route path="/ipdwardtypecreate" element={<IpdWardTypecreate />} />
              <Route path="/ipdwrdtypeupdate" element={<IpdWrdTypeUpdate />} />
              {/* hospital religin Setup */}
              <Route path="/religinDisplay" element={<ReliginDisplay />} />
              {/* ipd template Setup */}
              <Route path="/ipdTemplateSetup" element={<IPDMasterTemplateSetup />} />
              <Route path="/ipdTemplateupdate/:id" element={<EditIPDMasterTemplate />} />
              <Route path="/ipdTemplateadd" element={<AddIPDMasterTemplate />} />
            </Route>
            <Route path="/oTPFormDoctor" element={<OTPFormDoctors />} />
            <Route path="/passwordResetFormDoctors/:id" element={<PasswordResetFormDoctors />} />
            <Route path="/" element={<Sidebar2 />}>
              <Route path="/patientHome" element={<PatientHome />} />
              <Route path="/patient/allFamilyMembers" element={<AllFamilyMembers />} />
              <Route path="/patient/updatePatient/:id" element={<EditPatient />} />
              <Route path="/patient/addNewPatient" element={<AddNewPatient />} />
              <Route path="/patient/addPatientProfile/:id" element={<AddNewPatientProfile />} />
              <Route path="/patient/viewPatientProfile/:id" element={<ViewPatientProfile />} />
              <Route path="/patient/bookByDoctor" element={<BookByDoctor />} />
              <Route path="/patient/bookByDoctor/:id" element={<BookByDoctor />} />
              <Route path="/bookByDoctor/:name" element={<BookByDoctor />} />
              <Route path="/patient/bookAppointmentSlot/:userDetailId/:name/:expertise/:qualification_description" element={<BookAppointmentSlot />} />
              <Route path="/patient/bookBySpecialization" element={<BookBySpecialization />} />
              <Route path="/patient/bookBysymptoms" element={<BookBySymptom />} />
              <Route path="/patient/appointment" element={<Appointments />} />
              <Route path="/patient/bookByAppointmentSymptom/:name" element={<BookAppointmentSymptom />} />
              <Route path="/patient/viewProfile" element={<ViewProfile />} />
              <Route path="/patient/updatePasswardPatient" element={<UpdatePasswardPatient />} />
              <Route path="/patient/uploadReport/:id" element={<AppointmentUploadReport />} />
            </Route>
            <Route path="/patient/oTPForm" element={<OTPForm />} />
            <Route path="/patient/passwordResetForm/:id" element={<PasswordResetForm />} />
            <Route path="/ipdSummary/:id/:token" element={<IPDDischargeSummaryForAndroid />} />
          </Routes>
        </ProSidebarProvider>
      </Router>
    </React.Suspense>
  );
}

export default App;
